<template lang="pug">
.-markdown-view
  vue-markdown(:source='content')
</template>

<script>
import axios from 'axios'

export default {
  components: {
    VueMarkdown: () => import('vue-markdown')
  },

  async created() {
    let { data } = await axios.get(this.url)
    this.content = data
  },

  data: () => ({
    content: ''
  }),

  props: ['url']
}
</script>

<style lang="sass">
@import "@/assets/variables.sass"

.-markdown-view
  @import "@/assets/markdown-css-themes/modest"
  color: $DARK-COLOR-4
  font-size: 18px
  margin: 0 auto
  max-width: 48em
  padding: 2em
  @media screen and (max-width: 768px)
    font-size: 15px

  ol ul
    margin-bottom: 1.3em
    margin-left: 1.5em

h1, h2, h3, h4
  color: $DARK-COLOR-2
</style>
